import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import performRequest from '@/Framework/api/Http/performRequest';

interface RequestOption {
  method: 'POST' | 'GET',
    body: FormData,
    headers: Record<string, string>,
}

interface ApiResponse<T> {
  data: T,
  status: number,
}

type HttpRequestFunction = <T>(url: string, options: RequestOption) => Promise<ApiResponse<T>>;

class ModalFormRepository {
  private httpRequest: HttpRequestFunction;

  constructor(httpRequest) {
    this.httpRequest = httpRequest;
  }

  createForm(data) {
    let formData = new FormData();
    let names = Object.keys(data);

    names.forEach((name) => {
      formData.append(name, data[name]);
    });

    return formData;
  }

  sendCorrectionModalForm = async (value, dealId) => {
    let option = {
      headers: {},
      method: 'POST',
      body: this.createForm(value),
    };
    let response = await performRequest(finsightWebUrl.getApiUrl(`deals/${ dealId }/correction`), option);

    return response.json();
  };

  sendMissingSomethingModalForm = async (value) => {
    let option = {
      method: 'POST',
      body: this.createForm(value),
      headers: {},
    };
    let response = await performRequest(finsightWebUrl.getApiUrl('/mailing/tell-us'), option);

    return response.json();
  };

  joinOpenmarketWaitlist = async (value) => {
    let option = {
      method: 'POST',
      body: this.createForm(value),
      headers: {},
    };
    let response = await performRequest(finsightWebUrl.getApiUrl('/mailing/join-openmarket-waitlist'), option);

    return response.json();
  };

  suggestBond = async (payload) => {
    const option = {
      method: 'POST',
      body: this.createForm(payload),
      headers: {},
    };
    const response = await performRequest(finsightWebUrl.getApiUrl('/cusip-monitor/missing-deal'), option);

    return response.json();
  };
}

export default ModalFormRepository;
